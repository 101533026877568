<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Editor from '@tinymce/tinymce-vue'
// import router from "@/router/index.js";
//import Multiselect from "vue-multiselect";
//import CKEditor from "@ckeditor/ckeditor5-vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import Pagination from '../../../components/Pagination.vue'
//import { format } from "date-fns";
/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        Editor,
        // Pagination
        //Multiselect,
        //ckeditor: CKEditor.component,
    },
    data() {
        return {
            fields: [
                { key: "ville", label: "Ville" },
                { key: "rayon", sortable: true, label: "Rayon (Km)" },
                { key: "active", sortable: true, label: "Statut" },
                // { key: "montantAssurance", sortable: true, label: "Assurance" },
                // { key: "montantAttenteParMin", sortable: true, label: "Tarif attente", },
                { key: "action", sortable: true, label: "Action", },
            ],
            usersFields: [
                { key: "name", label: "Nom" },
                { key: "phone", sortable: true, label: "Téléphone" },
                { key: "country", sortable: true, label: "Pays" },
                // { key: "montantAssurance", sortable: true, label: "Assurance" },
                // { key: "montantAttenteParMin", sortable: true, label: "Tarif attente", },
                { key: "action", sortable: true, label: "Action", },
            ],
            cityData: [],
            showModal: false,
            city: {
                nom: "",
                longitude: null,
                latitude: null,
            },
            rayon: 0,
            areaUsers: [],
            filteredUsers: [],
            newRayonId: null,
            title: "FAQ",
            items: [
                {
                    text: "Dashboard",
                },
                {
                    text: "FAQ",
                    active: true,
                },
            ],
            totalRows: 1,
            changeCurrent: 1,
            changePerPage: 100,
            currentPage: 1,
            currentPageD: 1,
            perPage: 100,
            perPageD: 100,
            pageOptions: [100, 250, 500],
            pageOptionsD: [100, 250, 500],
            filter: null,
            filterOn: [],
            sortBy: "orderid",
            sortDesc: false,
            newRayon: null,
            coordinate: [],
            startLocation: {
                lat: 6.366667,
                lng: 2.433333,
            },
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        // rows() {
        //     return this.dataUser.length;
        // },
        // rowsinactif() {
        //     return this.dataUserD.length;
        // },
    },
    async mounted() {
        // await   this.dataMap()
    },
    created() {
        this.dataMap()
    },
    methods: {
        search() {
            if (this.filter) {
                this.filteredUsers = this.areaUsers.filter(el => el.name.toLowerCase().includes(this.filter.toLowerCase()) || el.phone.includes(this.filter))
                //this.filteredUsers
            } else {
                this.filteredUsers = []
            }
        },
        // GET /zones-courses
        async getUser() {
            const data = await apiRequest('GET', 'dash/user/actif', undefined, false)
            if (data && data.data) {
                console.log('getUser.data:', data.data)
                const formattedTable = data.data.map((user) => {
                    return {
                        id: user.id,
                        firstname: user.firstname + ' ' + user.lastname,
                        email: user.email,
                        phone: user.phoneCode + ' ' + user.phone,
                        sexe: user.sexe,
                        optionsH: {
                            position: {
                                lat: user.latVille,
                                lng: user.latVille,
                            },
                            title: user.ville,
                            icon: this.mapHomeMarker,
                        },
                        latVille: user.latVille,
                        lngVille: user.lngVille,
                        ville: user.ville,
                        emailVerified: user.emailVerified,
                        profilPicture: user.profilPicture ? user.profilPicture : null,
                        status: user.active,
                        createdAt: new Date(user.createdAt).toLocaleString("FR-fr"),
                    }
                })
                this.users = formattedTable
                console.log("this user", this.users)
                console.log("over")
            }
        },
        async dataMap() {
            const data = await apiRequest(
                "GET",
                "zones-courses"
            );
            if (data && data.status == 200) {
                console.log('activity', data.data)
                const map = data.data.map(element => {
                    return {
                        lat: element.departLat,
                        lng: element.departLng,
                    };
                })
                this.coordinate = map;
                console.log('this.mapedData', this.coordinate)
            }
        },
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row rowBase">
            <div class="col-lg-12 ">
                <div class="card p-4 ">
                    <div class="mb-4">
                        Zone d'activité
                    </div>
                    <div class="card-body">
                        <GmapMap :center="startLocation" :zoom="10" map-type-id="terrain"
                            style="width: 100%; height: 400px">
                            <gmap-info-window :options="infoOptions" :position="infoPosition" :opened="infoOpened"
                                @closeclick="infoOpened = false">
                                {{ infoContent }}
                            </gmap-info-window>
                            <GmapMarker v-for="(item, index) in coordinate" :key="index"
                                :position="{ lat: item.lat, lng: item.lng }" :clickable="true" :draggable="false" />
                        </GmapMap>

                        <Editor v-model="content" api-key="6z7bo0jx43facf9rinxcqklzjsqcjggj14blvingkobdtuz8" :init="{
                            toolbar_mode: 'sliding',
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                        }" initial-value="Faq ici" />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style>
.logo {
    display: block;
    margin: 0 auto 2rem;
}

@media (min-width: 1024px) {
    #sample {
        display: flex;
        flex-direction: column;
        place-items: center;
        width: 1000px;
    }
}
</style>
